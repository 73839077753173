body {
  margin: 0;
  padding: 0;
  background-color: #f4fbfe;
}
.App {
  margin: 0;
  overflow: hidden;
}

.auth {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.auth-img {
  width: 100%;
  height: 100%;
}
.auth-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.debug * {
  background-color: rgba(255, 0, 0, 0.25);
}
